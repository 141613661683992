import React from 'react'

// App components
import Accordian from '../../components/Accordian'
import CustomLink from '../../components/CustomLink'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App utils
import { themeStyles } from '../../utils/theme'

const accordianItems = [
  {
    title: 'Do I need a referral to visit the Orthodontist?',
    body: (
      <div>
        <p>
          You do not need a referral to visit our office. Simply call{' '}
          <CustomLink href="tel:15072585400">507-258-5400</CustomLink>.
        </p>
      </div>
    ),
  },
  {
    title: 'Do braces hurt?',
    body: (
      <div>
        <p>
          If you have ever tried googling the words “dentist” and “pain” then
          I’m sure you have seen pictures of a dentist holding one of two items
          – a drill or a needle. Nobody loves those things, but why? It’s
          because people associate pain with the dentist needing to give them a
          “shot” and then drilling on their teeth.
        </p>
        <p>
          The great news is, braces don’t generally require drilling on teeth or
          the need for shots! In fact, braces really are not painful at all.
          Over time your mouth will adjust to the new braces and you will barely
          notice they are even in your mouth.
        </p>
        <p>
          That being said, there is one time during your braces in which you may
          experience some mild discomfort, and that will be during the first few
          days in your braces. With some people, it lasts a couple of hours.
          With others, it can last up to a week or more.
        </p>
        <p>
          So after I tell people they will feel some mild discomfort during the
          first few days in braces, they often times want to know more –is it
          like “a tiny bit of pressure” or is it more like “a pile of bricks are
          being dropped on my head?”
        </p>
        <p>
          Here is the best analogy for the discomfort you may feel – imagine
          that you haven’t exercised for a while and then you decide you’re
          going to go out for a five-mile run and maybe hit the weight room
          afterward. You feel pretty good during the workout, but later that
          night your body may be a bit sore. It’s the same idea with braces.
          It’s not going to be extremely painful, but you’re going to be in some
          discomfort and achiness.
        </p>
        <p>
          With some people, it’s hardly noticeable and lasts just a day. With
          others, it may last a few days. Everyone responds a little
          differently. The good news is that A.) The discomfort often goes away
          within a few days so the remainder of your braces experience is great,
          and B.) You can plan for the initial discomfort by using
          over-the-counter pain medications on the day you get your braces.
        </p>
        <p>
          In fact, we often recommend a combination of Advil® and Tylenol®
          (provided you do not have any contraindications to these medications)
          before you get your braces on, and then again later that night. These
          two drug types work differently and can be taken together to alleviate
          the initial discomfort from your braces.
        </p>
        <p>
          The bottom line is that you may have some initial discomfort from the
          braces, but it should not be very painful and it will go away with
          time.
        </p>
      </div>
    ),
  },
  {
    title: 'Do you have to give up certain foods with braces?',
    body: (
      <p>
        With braces, there are some foods that you will need to avoid to make
        sure you do not break appliances. Most foods that are sticky and/or hard
        may cause issues. That being said, there are tons of foods that you may
        enjoy during your time in braces. In fact, if you haven’t checked out
        the Collins Orthodontics Braces Friendly Recipe channel, then I highly
        recommend you go check it out here:{' '}
        <CustomLink href="https://www.rochesterorthodontics.com/braces-friendly-eats">
          BracesFriendlyEats
        </CustomLink>
      </p>
    ),
  },
  {
    title: 'Do you have to give up playing sports or instruments?',
    body: (
      <p>
        You do not! After a few days adjusting to your braces, instruments and
        sports will not be a problem at all during your time in braces! If you
        play a contact sport, you can wear a special mouth guard that will
        protect your mouth and your appliance. That being said, you will want a
        “braces friendly” mouth guard that will accommodate the braces. You may
        get these types of mouth guards at most sporting good stores, or we do
        sell this specific type of mouth guard at our office as a convenience to
        our patients.
      </p>
    ),
  },
  {
    title: 'How much do braces cost?',
    body: (
      <div>
        <p>
          Braces will range anywhere between $3,000 to $5,000 for limited
          treatment and $5,000 to $8,000 for comprehensive care. At your free
          exam, our doctors will analyze your smile and bite and determine a
          number of options for you. At that time, you can decide which option
          best fits your specific concerns in order to make an informed
          decision.
        </p>
        <p>
          Also, be sure to ask our treatment coordinators about our flexible
          financing program. We offer discounts for payment in full or multiple
          family members in treatment; and then low monthly payment options for
          those looking to find something affordable with their budgets.
        </p>
        <p>
          We try to make orthodontic treatment accessible and affordable to all.
          Also, be sure to bring in your insurance information so we can help do
          a free benefits check for you. Many people have great orthodontic
          benefits that will help with payments!
        </p>
      </div>
    ),
  },
  {
    title: 'When should you bring your child to an orthodontist?',
    body: (
      <div>
        <p>
          The American Association of Orthodontists (AAO) recommends that your
          child first see an orthodontist at the age of seven. You do not need a
          referral from your primary care dentist in order to set up your
          child’s screening with an orthodontist.
        </p>
        <p>
          Many times your primary care dentist will recognize problems that need
          to be seen by an orthodontist and then place a referral to our office.
          We absolutely love it when dentists do this because it allows us to
          evaluate the problem to determine if treatment is needed, and when the
          best time will be to begin treatment.
        </p>
        <p>
          However, seeing an orthodontist for a screening at age 7 will make
          sure that nothing is overlooked and that all of the teeth are erupting
          and developing properly. So, your child should see an orthodontist by
          the age of seven. I know what you are thinking –“why in the world
          would my child need braces at age 7? That seems way too young!”
        </p>
        <p>
          Here is the deal – you are right that most seven-year-olds will not be
          getting braces. In fact, we found that it’s quite the opposite. The
          overwhelming majority of patients who come in for a screening do not
          need early treatment. However, the American Association of
          Orthodontists does recommend a screening at the age of 7 in order to
          catch and rule out some potentially very serious situations that are
          difficult to detect to the untrained eye.
        </p>
      </div>
    ),
  },
  {
    title: 'What types of braces are there for adults?',
    body: (
      <p>
        Adult braces is becoming incredibly common and popular. Whether you had
        braces as a kid and your teeth have shifted, or maybe you never had
        braces and finally want to do something about your smile, then you are
        not alone – 52% of our practice at Collins Orthodontics consists of
        adult patients! Although we do offer traditional (colored) braces to
        adults, the two most popular options are our Radiance Clear Braces
        (clearest braces offered in the orthodontic industry) or invisalign.
      </p>
    ),
  },
  {
    title: 'How long will you have to wear braces?',
    body: (
      <p>
        Most cases range between 12-24 months – some are longer, and some are
        shorter. It is important to know that your mouth is unique, so length of
        treatment varies from person to person. Collins Orthodontics offers
        current technology that allows you to have faster overall treatment
        times with better results.
      </p>
    ),
  },
  {
    title: 'Will you have to wear a retainer for the rest of your life?',
    body: (
      <div>
        <p>
          Yes, you do. But it won’t be as bad as you think, I promise (keep
          reading to find out why). What is important is that you understand
          when to wear your retainer and when it’s okay not to wear your
          retainer.
        </p>
        <p>
          Here is how it works – when you get your braces off, your teeth will
          immediately want to go back to their original positions. The bone
          surrounding your teeth is essentially what is holding them in place.
          That bone is soft at the end of treatment because it has been
          remodeled many times to allow your teeth to move to their new
          positions.
        </p>
        <p>
          Much like wet cement, your bone just needs time to harden around the
          teeth. Obviously there are many other biological processes going on
          that make the process much more complicated than cement drying, but
          the idea is very much the same. For that reason, your orthodontist
          will initially want you to wear your retainers at a much higher
          frequency and longer duration immediately after you get your braces
          off.
        </p>
        <p>
          Over time, your orthodontist should gradually decrease the amount of
          time you’ll need to wear your retainers. After several years, it
          should be okay for you to wear your retainers only several nights a
          week. I wish I could tell you that your teeth will stay put without
          needing retainers, but I would just be leading you down a road of
          frustration and disappointment. If you currently have a retainer, then
          you will likely fall into one of the categories listed below. It is
          important for you to know what you should do when you fall into one of
          the following categories:
        </p>
        <ul>
          <li>
            <strong>Retainers fitting well and not tight</strong> – You should
            continue to wear your retainer 2 to 4 nights per week.
          </li>
          <li>
            <strong>
              Retainers fitting okay (i.e. they can be fully seated on your
              teeth), but they are very tight
            </strong>{' '}
            – You must increase the use of your retainers to every night or even
            every day for a period of several weeks. The retainer can be used to
            actively “push” your teeth back to their original positions, but
            only if you are able to fully seat the retainer in your mouth. Once
            the retainer does not feel tight anymore, then you can decrease the
            use to 2 to 4 nights per week.
          </li>
          <li>
            <strong>Retainers do not fit at all</strong> – If you cannot fully
            seat your retainers, then you should call your orthodontist
            immediately. Your teeth have shifted BEYOND the point of using your
            old retainers to push them back into place. When you meet with your
            orthodontist, you will have three main options:
            <ol>
              <li>
                <strong>Do nothing.</strong> Even though this is an option, the
                main risk is that your teeth will continue to shift over time.
              </li>
              <li>
                <strong>Make a new retainer.</strong> Making a new retainer will
                hold the CURRENT position of your teeth. It will NOT push your
                teeth back to where they were at the end of your orthodontic
                treatment (that’s what your old retainer could have done if you
                were able to fully seat it), but it WILL prevent your teeth from
                continuing to shift into worse positions.
              </li>
              <li>
                <strong>Get treatment to correct the shifted teeth.</strong>{' '}
                This will likely be in the form of braces or invisalign. If it’s
                just one tooth out of alignment, there may even be some limited
                treatment options with shorter treatment times. If you fall into
                one of the above categories, or you have specific questions
                regarding retainer care and maintenance, feel free to call our
                office at any time.
              </li>
            </ol>
          </li>
        </ul>
      </div>
    ),
  },
]
class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle title="Common Questions" bannerKey={'THE_NEXT_STEP'} />
          <div
            css={[
              themeStyles.textPadding,
              themeStyles.innerTextBreak,
              themeStyles.elementMargin,
            ]}
          >
            <Accordian items={accordianItems} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
