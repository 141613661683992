import React from 'react'

import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import { colors } from '../utils/theme'

const styles = {
  closed: {
    display: 'none',
  },
  title: {
    cursor: 'pointer',
  },
}

class Item extends React.Component {
  state = {
    open: false,
  }

  onClick = (e) => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const icon = this.state.open ? (
      <FaMinusCircle color={colors.brightBlue} />
    ) : (
      <FaPlusCircle color={colors.brightBlue} />
    )
    return (
      <div key={this.props.item.title}>
        <div onClick={this.onClick} css={styles.title}>
          <h3>
            {' '}
            {icon} {this.props.item.title}
          </h3>
        </div>
        <div css={this.state.open ? {} : styles.closed}>
          <p>{this.props.item.body}</p>
        </div>
      </div>
    )
  }
}

export default ({ items = [] }) => {
  const markup = items.map((item) => <Item item={item} />)
  return <div>{markup}</div>
}
